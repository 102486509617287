<template>
  <b-card
    :title="product.title"
    :img-src="thumbnail[0]"
    img-alt="product"
    img-top
    tag="article"
    class="card mb-4"
  >
    <b-card-sub-title class="mb-2">{{ product.brand }}</b-card-sub-title>
    <b-card-text>
      {{ shortenDescription }}
    </b-card-text>

    <div class="card__actions">
        <button 
          class="card__update card__button"
          @click="$router.push({ name: 'product-update', params: { id: product.id } })"
          >
          Actualizar
          </button>
        <button 
        @click="deleteCurrentProduct"
        class="card__delete card__button"
        >Eliminar</button>
    </div>
    <template #footer>
      <p>{{date}}</p>
    </template>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },

  methods: {

    async deleteCurrentProduct() {
      const confirmation = confirm('Deseas borrar el producto ' + this.product.title + '?')
      if(!confirmation) {
        return;
      }
      try {
        await this.deleteProduct({ id: this.product.id, images: this.product.photos })
        this.$toast.success('Se elimino el producto correctamente')
      } catch (error) {
        this.$toast.error('Ocurrio un error al intentar borrar el producto')
      }
    },
    ...mapActions('webStore', ['deleteProduct'])
  },

  computed: {
    shortenDescription() {
      return this.product.description.length > 100
        ? this.product.description.substring(0, 100) + "..."
        : this.product.description;
    },

    thumbnail(){
      return this.product.photos.filter(e => e.includes('jpg'))
    },

    date() {
        const date = new Date(this.product.date)
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    }
  },
};
</script>

<style>
</style>
